import React from "react"
import Helmet from "react-helmet"

const MetaCards = ({ data }) => {
  return (
      <Helmet>
        <title>{data.pageTitle}</title>
        <meta name={data.pageMetaDescription} content={data.pageMetaDescription} />
		    <meta name={data.pageMetaKeywords} content={data.pageMetaKeywords} />
      </Helmet>
  )
}

export default MetaCards
