import React from "react"

const Visit = ({ data }) => {
  return (
    <section className="visit-sec">
      <div className="container">
        <div className="row">
          <div className="col col-md-5 visit-sec-img">
            <img src={data.image1.file.url} alt="#" />
            <img src={data.image2.file.url} alt="#" />
          </div>
          <div className="col col-md-7 visit-sec-content d-flex align-items-center">
            <div>
              <h2>{data.title}</h2>
              <p>{data.content.content}</p>
              <a href={data.url}>
                <button type="button" className="btn d-btn">
                  {data.label}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Visit
