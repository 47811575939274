import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import MetaCards from "../components/common/meta-cards"
import Hero from "../components/testimonials/hero"
import Stories from "../components/testimonials/stories"
import TestimonialsSection from "../components/testimonials/testimonials"
import Visit from "../components/shared/visit"

const Testimonials = ({ data }) => {
  return (
    <Layout>
      <Seo title="Testimonials" />
       <MetaCards data={data.contentfulTestimonialsPage.webMeta}/>
      <Hero />
      <TestimonialsSection />
      <Stories />
      <Visit data={data.contentfulTestimonialsPage.scheduleAVisit} />
    </Layout>
  )
}

export default Testimonials

export const query = graphql`
  {
    contentfulTestimonialsPage {
      webMeta {
        pageTitle
        pageMetaDescription
        pageMetaKeywords
      }
      scheduleAVisit {
        image1 {
          file {
            url
          }
        }
        image2 {
          file {
            url
          }
        }
        title
        content {
          content
        }
        label
        url
      }
    }
  }
`
