import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulTestimonialsPage {
        heroTitle
        heroContent {
          heroContent
        }
      }
    }
  `)
  const { heroTitle, heroContent } = data.contentfulTestimonialsPage
  return (
    <section className="referral-hero test-page-hero">
      <div className="container">
        <div className="row">
          <div className="col">
            <h1>{heroTitle}</h1>
            <p>{heroContent.heroContent}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
