import React, {useState, useEffect, useRef} from "react"
import { useStaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
import Modal from "react-modal";
import Select from "react-select"

const customStyles = {
  control: (base, state) => ({
    ...base,
    // background: "transparent",
    border: "2px solid #fff",
    color: "#000",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#ff0245" : "#ff0245",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#ff0245" : "#ff0245",
      boxShadow: state.isFocused ? null : null,
      color: state.isFocused ? "brown" : "grey"
    },
  }),
  menu: (base, state) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    border: "1px solid #ff0245",
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    color: "#262b4a",
    color: state.isFocused ? "orange" : "#ff0245",
  }),
}

  const genderOptions = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ]

  const nOrSOptions = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ]


const Stories = () => {

  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const slider = React.useRef(null)

  var PetStoriesSlider = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
    appendDots: dots => <><div className="testimonial-slider-dots"><ul>{dots}</ul></div></>,
		responsive: [
		{
		  breakpoint: 767,
		  settings: {
		    slidesToShow: 1,
		    slidesToScroll: 1
		  }
		}
		]
  };
  
  const data = useStaticQuery(graphql`
    {
      contentfulTestimonialsPage {
        storiesTitle
        storiesContent {
          storiesContent
        }
        stories {
          id
          testimonial {
            testimonial
          }
          client
          image {
            file {
              url
            }
          }
          url
        }
      }
    }
  `)
  const { storiesTitle, storiesContent, stories } =
    data.contentfulTestimonialsPage
  
  Modal.setAppElement("#___gatsby");

  useEffect(() => {
    if (window.location.href.indexOf("share-story") > -1) {
      toggleModal();
    };
  }, []);
  
  return (
    <section className="testimonial-sec testimonial-sec-n">
      <div className="container">
        <div className="row">
          <div className="col testimonial-sec-n-top">
            <h2 className="text-center">{storiesTitle}</h2>
            <p>{storiesContent.storiesContent}</p>
          </div>
          <div className="col testimonial-slider testimonial-sec-n-slider">
            <Slider {...PetStoriesSlider} ref={slider}>
              {stories.map(story => (              
                <div key={story.id}>
                  <div className="testimonial-box">
                    <div className="row m-0">
                      <div className="col col-md-6">
                        <img src={story.image.file.url} alt="#" />
                      </div>
                      <div className="col col-md-6 d-flex align-items-center">
                        <div>
                          <h2>{story.client}</h2>
                          <p>{story.testimonial.testimonial}</p>
                          <a href={story.url} className="link-btn">
                            Read More
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.243"
                              height="13.501"
                              viewBox="0 0 20.243 13.501"
                            >
                              <path
                                id="Icon_ionic-ios-arrow-round-forward"
                                data-name="Icon ionic-ios-arrow-round-forward"
                                d="M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z"
                                transform="translate(-7.875 -11.252)"
                                fill="#ff0245"
                              ></path>
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
             <button className="nextSlickBtn" onClick={toggleModal}> 
            Share Your Pet's Story
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.243"
              height="10.501"
              viewBox="0 0 20.243 13.501"
            >
              <path
                id="Icon_ionic-ios-arrow-round-forward"
                data-name="Icon ionic-ios-arrow-round-forward"
                d="M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z"
                transform="translate(-7.875 -11.252)"
                fill="#151515"
              />
          </svg>
          </button>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel="My dialog"
          >
            {/* <div className="modal-inner-wrap">
                <h2>Submit Your Review</h2>
            </div> */}
          <div className="container referral-form-con">
            <div className="row">
              <div className="col">
                <h2>Submit Your Review</h2>
              </div>
              <div className="col col-md-6 referral-form-sec-inner">
                  <label>
                    <input
                      type="text"
                      name="fName"
                      placeholder="Name of Client*"
                      // value={fName}
                      // onChange={handleChange}
                    />
                  </label>
                  <label>
                    <input
                      type="text"
                      name="phone"
                      placeholder="Email Address*"
                      // value={phone}
                      // onChange={handleChange}
                    />
                  </label>
                  <label>
                    <input
                      type="text"
                      name="breed"
                      placeholder="City"
                      // value={breed}
                      // onChange={handleChange}
                    />
                  </label>
              </div>
              <div className="col col-md-6 referral-form-sec-inner second-call">
                  <label>
                    <input
                      type="text"
                      name="petName"
                      placeholder="Name of Pet*"
                      // value={petName}
                      // onChange={handleChange}
                    />
                  </label>
                  <label>
                    <input
                      type="text"
                      name="phone"
                      placeholder="Phone Number*"
                      // value={phone}
                      // onChange={handleChange}
                    />
                  </label>
                  <label>
                  <Select
                    options={genderOptions}
                    className={`customDropDown`}
                    classNamePrefix={`customDropDown`}
                    required
                    styles={customStyles}
                    placeholder={`Is Your Pet Currently A Patient?*`}
                    name="isPatient"
                    // value={isPatient}
                    // onChange={onChangeIsPatient}
                  />
                  </label>
              </div>  
              <div className="col">
                 <Select
                    options={genderOptions}
                    className={`customDropDown customPetServe`}
                    classNamePrefix={`customDropDown`}
                    required
                    styles={customStyles}
                    placeholder={`How Did We Serve Your Pet?*`}
                    name="isPatient"
                    // value={isPatient}
                    // onChange={onChangeIsPatient}
                  />
                  <textarea
                    placeholder="Share Your Story"
                    // name="history"
                    // value={history}
                    // onChange={handleChange}
                  ></textarea>
                  <div className="uploadImgBar">
                    <h3>Upload Photo <span>(Size Limit: 2Mb)</span></h3>
                    <input type="file"
                      id="petImg" name="petImg"
                      accept="image/png, image/jpeg"/>
                  </div>
                  <button id="submit">Submit</button>
              </div>         
            </div>
   
          </div>
          <button className="closeModalBtn" onClick={toggleModal}><svg xmlns="http://www.w3.org/2000/svg" width="17.688" height="17.688" viewBox="0 0 17.688 17.688"><path id="close-icon" d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z" transform="translate(-7.5 -7.5)" fill="#FF0245"></path></svg></button>
          </Modal>
          </div>
          <div className="col testimonial-slider-col d-flex justify-content-between">
            <div className="testimonial-slider-dots"></div>
            <div className="testimonial-slider-nav"></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Stories